import { Alert, AlertColor, IconButton, Snackbar } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { ReactNode } from 'react';

export interface SnackBarContextProps {
  createSnack: (message: string, severity?: AlertColor) => number;
}

export interface RenderSnackProps {
  id: number;
  message: string;
  severity?: AlertColor;
  open: boolean;
  handleClose: () => void;
}

export const SnackBarContext = createContext<SnackBarContextProps>({ createSnack: (_message: string, _severity?: AlertColor) => Date.now() });

function RenderSnack({
  id,
  message,
  severity,
  open,
  handleClose,
}: RenderSnackProps) {
  const messageId = `message-${id}`;
  if (severity) {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': messageId,
        }}
      >
        <Alert onClose={handleClose} severity={severity} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    );
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': messageId,
      }}
      message={message}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          X
        </IconButton>,
      ]}
    />
  );
}

interface Snack {
  id: number,
  message: string,
  open: boolean,
  severity: AlertColor | undefined,
}

function SnackBarProvider(props: { children: ReactNode }): JSX.Element {
  const { children } = props;
  const [{ current, queue }, setState] = useState<{ current: Snack | null, queue: Snack[] }>(
    {
      current: null,
      queue: [],
    },
  );

  function createSnack(message: string, severity: AlertColor | undefined) {
    const id = Date.now();
    const snack = { id, message, open: true, severity };

    if (current) {
      setState({ current, queue: queue.concat(snack) });
    } else {
      setState({ queue, current: snack });
    }

    return id;
  }

  function openNext() {
    if (queue.length) {
      setState({ current: queue[0], queue: queue.slice(1) });
    } else {
      setState({ current: null, queue: [] });
    }
  }

  function handleClose() {
    setState((currentState: { current: Snack | null, queue: Snack[] }) => currentState.current ? ({
      ...currentState,
      current: { ...currentState.current, open: false },
    }) : currentState);
    // time to snack close animation
    setTimeout(openNext, 1000);
  }

  return (
    <SnackBarContext.Provider
      value={{ createSnack }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <>
        {current && (
          <RenderSnack
            key={current.id}
            id={current.id}
            open={current.open}
            severity={current.severity}
            message={current.message}
            handleClose={handleClose}
          />
        )}
        {children}
      </>
    </SnackBarContext.Provider>
  );
}

export const useSnackBarProvider = () => {
  const { createSnack } = useContext(SnackBarContext);
  return { createSnack };
}
  

export default SnackBarProvider;
