import { AppBar, styled, Toolbar, Grid } from "@mui/material";
import React, { ReactNode } from "react";
import Image from "next/image";
import Head from "next/head";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

function Layout({ children }: { children: ReactNode }): JSX.Element {
  return (
    <div>
      <Head>
        <link
          rel='preload'
          href='/fonts/1475958/8186c8c1-cfdb-4c3e-b38e-6c2fd28486b5.ttf'
          as='font'
          crossOrigin=''
        />
        <link
          rel='preload'
          href='/fonts/1475946/5b8d7867-e8ee-4484-95aa-6e4bff75f379.ttf'
          as='font'
          crossOrigin=''
        />
        <link
          rel='preload'
          href='/fonts/1475970/bd4ea697-ae5b-421a-9a3c-d94f6f0ab7c0.ttf'
          as='font'
          crossOrigin=''
        />
      </Head>
      <nav>
        <AppBar
          position='fixed'
          color='default'
          sx={{ backgroundColor: "white" }}
        >
          <Toolbar variant='dense'>
            <Grid container justifyContent='flex-end' alignItems='center'>
              <Grid item sx={{ display: "flex" }}>
                <Image
                  src='/assets/img/logo.svg'
                  alt='Rega Logo'
                  width={30}
                  height={30}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Offset />
      </nav>
      <main>{children}</main>
    </div>
  );
}

export default Layout;
